import React, { useState, useEffect, useRef } from 'react';
import { MirList } from 'shared/settings/apiconfig';
import Datatable from 'shared/datatable';

const GD = ({ filter, mirstatus,saleidfilter, saleid }) => {
    const initialSearchState = {
        saleid:  { field: "saleid", value: saleid, op: "=", join: "AND"} 
    };

    const datatable = useRef(null);

    const [search, setSearch] = useState(initialSearchState);
    const [config, setConfig] = useState({
        url: MirList,
        params: {},
        columns: [
            { name: 'Item Title', property: 'itemtitle', sort: false, width: '250px'},
            { name: 'Invoice Number', property: 'invoicenumber', sort: false, width: '130px' },
            { name: 'Email', property: 'buyeremail', sort: false, width: '130px' },
            { name: 'SKU', property: 'SKU', sort: false, width: '130px' },
            { name: 'Contract ID', property: 'contractid', sort: false, width: '130px' },
            { name: 'Contract Status', property: 'contractstatus', sort: false, width: '130px' },
            { name: 'Claim ID', property: 'claimid', sort: false, width: '130px' },
            { name: 'Claim Status', property: 'claimstatus', sort: false, width: '150px' },
        ],
        pagination: true,
        field: ["id","orderid","contractid","contractstatus","claimid","claimstatus","itemtitle","SKU","category","invoicenumber","buyeremail"],   
        ignoreRowBackground: true
    });

    useEffect(() => {
        const mergedFilter = { ...initialSearchState, ...filter };
        setSearch(mergedFilter);
    }, [filter]);

    useEffect(() => {
        if (mirstatus) { 
        if (datatable.current) {
            datatable.current.searchData();
        }
    }

    }, [search]);

    return (
        <div className="">
            <Datatable config={{ ...config }} search={{ search: { ...search }, searchData: true }} ref={datatable} ></Datatable>
        </div>
    );
};

export default GD;
